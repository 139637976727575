import * as React from 'react';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { Breadcrumb, BreadcrumbItem, Button, Checkbox, EmptyState, EmptyStateBody, EmptyStateIcon, EmptyStateVariant, Modal, ModalVariant, PageSection, Split, SplitItem, Stack, Title } from '@patternfly/react-core';
import { Assignment } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { Link, useParams } from 'react-router-dom';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { SearchIcon } from '@patternfly/react-icons';

type ManageAssignmentsProp = {
  classId: string;
};

type AssignmentsChecked = {
  id: number;
  assignment: Assignment;
  checked: boolean;
};

const renderEmptyState = (dataType: string, classId: string) => (
  <EmptyState variant={EmptyStateVariant.xs}>
    <EmptyStateIcon icon={SearchIcon} />
    <Title headingLevel="h5" size="lg">
      {`No ${dataType}s available`}
    </Title>
    <EmptyStateBody>
      {`There are currently no ${dataType}s to display. `}
      <Link to={`/edu/class/${classId}/add${dataType}s`}>
        Add one
      </Link>
    </EmptyStateBody>
  </EmptyState>
);

const ManageAssignments: React.FunctionComponent = () => {
  const { classId } = useParams<ManageAssignmentsProp>();
  const conf = React.useContext(GeneralSettingsContext);
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const [assignments, setAssignments] = React.useState<AssignmentsChecked[]>([]);
  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [deleteRelatedMaterial, setDeleteRelatedMaterial] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [numAssignsToDelete, setNumAssignsToDelete] = React.useState<number>(0);

  React.useMemo(async () => {
    await client
      .getAssignments({
        classId: classId,
      })
      .then((response) => {
        let assignmentsChecked: AssignmentsChecked[] = [];
        response.assignments.map((a, i) => {
          assignmentsChecked.push({ id: i, assignment: a, checked: false });
        });
        setAssignments(assignmentsChecked);
      });
  }, [classId]);

  const handleCheck = (event: React.FormEvent<HTMLInputElement>, isSelected: boolean, rowId: number) => {
    let newAc = assignments.map((ac) => {
      if (ac.id === rowId) {
        ac.checked = isSelected;
      }
      return ac;
    });
    setAssignments(newAc);
    setNumAssignsToDelete(numAssignsToDelete => isSelected ? numAssignsToDelete + 1 : numAssignsToDelete - 1)
  };

  const handleAllCheck = (event: React.FormEvent<HTMLInputElement>, isSelected: boolean) => {
    let newAc: AssignmentsChecked[] = [];
    assignments.map((ac) => {
      newAc.push({ ...ac, checked: isSelected });
    });
    setAllChecked(isSelected);
    setAssignments(newAc);
    setNumAssignsToDelete(isSelected ? newAc.length : 0);
  };

  const handleDeleteSelected = () => {
    if (numAssignsToDelete == 0) return;

    setIsModalOpen(true);
  };

  React.useEffect(() => {
    let newAllChecked = true;
    assignments.map((ac) => {
      if (!ac.checked) newAllChecked = false;
    });

    // Fix bug that sets allCheck to true when there are no assignments
    setAllChecked(assignments.length == 0 ? false : newAllChecked);
  }, [assignments]);

  const handleModalDelete = () => {
    console.log(deleteRelatedMaterial)

    let assignsToDelete: string[] = [];
    assignments.map((a) => {
      if (a.checked) assignsToDelete.push(a.assignment.assignmentId);
    });

    client.deleteAssignments({
      assignmentIds: assignsToDelete,
      classId: classId,
    });

    let newAssigns: AssignmentsChecked[] = assignments.filter((a) => {
      return !assignsToDelete.includes(a.assignment.assignmentId);
    });
    setAssignments(newAssigns);
    setIsModalOpen(false);
  }

  return (
    <React.Fragment>
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}`}>Class "{classId}"</BreadcrumbItem>
          <BreadcrumbItem isActive>Manage "{classId}" Assignments</BreadcrumbItem>
        </Breadcrumb>
        <Modal
          variant={ModalVariant.small}
          title="Confirm Delete?"
          isOpen={isModalOpen}
          onClose={() => { setIsModalOpen(false) }}
          actions={[
            <Button key="confirm" variant="danger" onClick={() => {
              setDeleteRelatedMaterial(false);
              handleModalDelete();
            }}>
              Yes
            </Button>,
            // <Button key="confirm" variant="danger" onClick={() => {
            //   setDeleteRelatedMaterial(true);
            //   handleModalDelete();
            // }}>
            //   Yes, and delete related materials too
            // </Button>,
            <Button key="cancel" variant="primary" onClick={() => { setIsModalOpen(false) }}>
              No
            </Button>,
          ]}
        >
          {
            allChecked
              ? "Are you sure you want to delete all assignments? This cannot be undone"
              : `Are you sure you want to delete ${numAssignsToDelete == 1 ? "this assignment" : "these assignments"}? This cannot be undone.`
          }
        </Modal>
        <Title headingLevel="h1">Manage Assignments</Title>
        Click on an assignment below to edit it
        <Stack hasGutter>
          <TableComposable variant="compact" borders={false}>
            <Thead>
              <Tr>
                <Th
                  select={assignments.length == 0 ? undefined : {
                    onSelect: (event: React.FormEvent<HTMLInputElement>, isSelected: boolean) =>
                      handleAllCheck(event, isSelected),
                    isSelected: allChecked,
                  }}
                />
                <Th>Assignment Name</Th>
              </Tr>
            </Thead>
            {assignments.length == 0 && (
              <Tbody>
                <Tr>
                  <Td colSpan={2}>{renderEmptyState('assignment', classId)}</Td>
                </Tr>
              </Tbody>
            )}
            {assignments.map((ac) => {
              const a = ac.assignment;
              return (
                <Tbody key={`manage-assignments-table-body-${ac.id}`}>
                  <Tr>
                    <Td
                      select={{
                        rowIndex: ac.id,
                        onSelect: (event: React.FormEvent<HTMLInputElement>, isSelected: boolean) =>
                          handleCheck(event, isSelected, ac.id),
                        isSelected: ac.checked,
                      }}
                    />
                    <Td dataLabel={'Assignment Name'}>
                      <Link to={`/edu/class/${classId}/assignment/${a.assignmentId}`}>{a.displayName}</Link>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          </TableComposable>
          <Split>
            <SplitItem>
              <Button variant="danger" onClick={handleDeleteSelected}>
                Delete Selected
              </Button>
            </SplitItem>
          </Split>
        </Stack>
      </PageSection>
    </React.Fragment>
  );
};

export { ManageAssignments };

