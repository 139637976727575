import * as React from 'react';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  ActionGroup,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Bullseye,
  Button,
  Form,
  FormAlert,
  FormGroup,
  Modal,
  ModalVariant,
  PageSection,
  Radio,
  Spinner,
  TextInput,
  Title,
} from '@patternfly/react-core';
import { Material, Class, UserType } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

type MaterialProp = {
  classId: string;
  materialId: string;
};

type ValidatedProps = 'default' | 'success' | 'warning' | 'error';

const MaterialPage: React.FunctionComponent<MaterialProp> = () => {
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;
  const { classId, materialId } = useParams<MaterialProp>();
  const conf = React.useContext(GeneralSettingsContext);
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const [url, setUrl] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [material, setMaterial] = React.useState<Material>();
  const [response, setResponse] = React.useState<boolean>(false);
  const [classroom, setClassroom] = React.useState<Class>();
  const [visibility, setVisibility] = React.useState<string>('');
  const [titleValidated, setTitleValidated] = React.useState<ValidatedProps>('success');
  const [urlValidated, setUrlValidated] = React.useState<ValidatedProps>('success');
  const [formValidated, setFormValidated] = React.useState<ValidatedProps>('default');
  const [urlHelperText, setUrlHelperText] = React.useState<string>('');
  const [visibilityValidated, setVisibilityValidated] = React.useState<ValidatedProps>('success');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  React.useMemo(async () => {
    await client
      .getClass({
        classId: classId,
      })
      .then((res) => {
        setResponse(true);
        setClassroom(res.class);
      });

    await client
      .getMaterial({
        classId: classId,
        materialId: materialId,
      })
      .then((res) => {
        const mat = res.material;
        setMaterial(mat);
        setTitle(mat ? mat.displayName : title);
        setUrl(mat ? mat.url : url);
        setVisibility(mat ? (mat.isVisibleToAll ? 'all' : 'prof') : '');
      });
  }, [classId]);

  if (classroom == undefined) {
    if (response) {
      return (
        <React.Fragment>
          <PageSection>
            <Alert variant="danger" title="Not Found Error">
              <pre>This class does not exist</pre>
            </Alert>
          </PageSection>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        </React.Fragment>
      );
    }
  } else if (
    classroom.users[username] == undefined ||
    classroom.users[username] == UserType.STUDENT ||
    classroom.users[username] == UserType.UNSPECIFIED
  ) {
    return (
      <React.Fragment>
        <PageSection>
          <Alert variant="danger" title="Permission Error">
            <pre>You do not have permission to access this content</pre>
          </Alert>
        </PageSection>
      </React.Fragment>
    );
  }

  const handleTitle = (value: string) => {
    // Clear banner if present
    if (formValidated != 'default') {
      setFormValidated('default')
    }

    setTitle(value);
    value = value.trim();
    if (value == '') {
      setTitleValidated('error');
    } else {
      setTitleValidated('success');
    }
  };

  const handleUrl = (value: string) => {
    // Clear banner if present
    if (formValidated != 'default') {
      setFormValidated('default')
    }

    /* Regex to check if it is a legal url
     * (mostly checking for http/https because otherwise it tries
     * to redirect to a page in launch that most likely doesn't exist)
     */
    const urlRegex = new RegExp('\\b((?:(https?|ftp)://)[\\w-]+(.[\\w-]+)+[\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-]?)');

    setUrl(value);
    value = value.trim();
    if (value == '') {
      setUrlValidated('error');
    } else if (!urlRegex.test(value)) {
      setUrlValidated('warning');
      setUrlHelperText('May not be a valid URL (you should include "http://" or "https://" at the beginning)');
    } else {
      setUrlValidated('success');
      setUrlHelperText('');
    }
  };

  const handleVisibility = (checked: boolean, event: React.FormEvent<HTMLInputElement> | undefined) => {
    // Clear banner if present
    if (formValidated != 'default') {
      setFormValidated('default')
    }

    if (!event) {
      if (visibility == '') {
        setVisibilityValidated('error');
      }
    } else {
      switch (event.currentTarget.id) {
        case 'visibility-radio-1':
          setVisibility('all');
          setVisibilityValidated('success');
          break;
        case 'visibility-radio-2':
          setVisibility('prof');
          setVisibilityValidated('success');
          break;
        default:
          setVisibility('');
          setVisibilityValidated('error');
          break;
      }
    }
  };

  const handleSubmit = async () => {
    // Clear banner if present
    if (formValidated != 'default') {
      setFormValidated('default')
    }

    // Check for validity
    if (titleValidated != 'success' || urlValidated != 'success' || visibility == '') {
      setFormValidated('error');
      handleTitle(title);
      handleUrl(url);
      handleVisibility(true, undefined);
      return;
    } else {
      setFormValidated('success');
    }

    // Update Material
    await client
      .updateMaterial({
        materialId: materialId,
        displayName: title,
        classId: classId,
        url: url,
        isVisibleToAll: visibility === 'all',
      })
      .catch(() => {
        // Using warning to indicate 409 Conflict
        setFormValidated('warning');
      });
  };

  return (
    <React.Fragment>
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}`}>Class "{classId}"</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}/materials`}>Manage "{classId}" Materials</BreadcrumbItem>
          <BreadcrumbItem isActive>Update Material "{material?.displayName}"</BreadcrumbItem>
        </Breadcrumb>
        <Title headingLevel="h1">{`Edit "${material?.displayName}"`}</Title>
        <Form>
          {formValidated === 'error' && (
            <FormAlert>
              <Alert
                variant="danger"
                title="Fill out all required fields before continuing."
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          {formValidated === 'warning' && (
            <FormAlert>
              <Alert variant="danger" title="Material already exists." aria-live="polite" isInline />
            </FormAlert>
          )}
          {formValidated === 'success' && (
            <FormAlert>
              <Alert variant="success" title="Successfully updated material." aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup
            label="Title"
            isRequired
            fieldId="add-material-title"
            helperTextInvalid={'Title cannot be empty'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={titleValidated}
          >
            <TextInput
              validated={titleValidated}
              isRequired
              type="text"
              id="add-material-title"
              name="add-material-title"
              value={title}
              onChange={handleTitle}
            />
          </FormGroup>
          <FormGroup
            label="Material URL"
            isRequired
            fieldId="add-material-url"
            helperText={urlHelperText}
            helperTextInvalid={'URL cannot be empty'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={urlValidated}
          >
            <TextInput
              validated={urlValidated}
              isRequired
              type="url"
              id="add-material-url"
              name="add-material-url"
              value={url}
              onChange={handleUrl}
            />
          </FormGroup>
          <FormGroup
            isRequired
            role="radiogroup"
            isInline
            fieldId="visibility-radio-group"
            label="Visibility"
            helperTextInvalid={'Select visibility'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={visibilityValidated}
          >
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'all'}
              label="All"
              id="visibility-radio-1"
            />
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'prof'}
              label="Professors/TAs Only"
              id="visibility-radio-2"
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="link" onClick={() => history.back()}>
              Cancel
            </Button>
            {/* <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button> */}
          </ActionGroup>
        </Form>
      </PageSection>
    </React.Fragment>
  );
};

export { MaterialPage };

