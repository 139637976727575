import { useCallback } from 'react';
import { Configuration, FrontendApi, IdentityApi } from '@ory/client';
import axiosFactory from 'axios';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { resilience } from './axios';

const axios = axiosFactory.create();
resilience(axios); // Adds retry mechanism to axios
const basePath = env.REACT_APP_MERGE_AUTH_URI;

const config = new Configuration({
  basePath,
  baseOptions: {
    withCredentials: true,
    // Timeout after 5 seconds.
    timeout: 10000,
  },
});

/**
 * @param getFlow - Should be function to load a flow make it visible (Login.getFlow)
 * @param setFlow - Update flow data to view (Login.setFlow)
 * @param defaultNav - Default navigate target for errors
 * @param fatalToDash - When true and error can not be handled, then redirect to dashboard, else rethrow error
 */
export const sdkError = (
  getFlow: ((flowId: string) => Promise<void | AxiosError>) | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFlow: React.Dispatch<React.SetStateAction<any>> | undefined,
  defaultNav: string | undefined,
  fatalToDash = false
) => {
  const history = useHistory();

  return useCallback(
    (error: AxiosError<any, unknown>): Promise<AxiosError | void> => {
      const responseData = error.response?.data || {};

      switch (error.response?.status) {
        case 400: {
          if (error.response.data?.error?.id === 'session_already_available') {
            console.warn('sdkError 400: `session_already_available`. Navigate to /');
            history.push('/', { replace: true });
            return Promise.resolve();
          }
          // the request could contain invalid parameters which would set error messages in the flow
          if (setFlow !== undefined) {
            console.warn('sdkError 400: update flow data');
            setFlow(responseData);
            return Promise.resolve();
          }
          break;
        }
        case 401: {
          console.warn('sdkError 401: Navigate to /login');
          history.push('/login', { replace: true });
          return Promise.resolve();
        }
        case 403: {
          // the user might have a session, but would require 2FA (Two-Factor Authentication)
          if (responseData.error?.id === 'session_aal2_required') {
            history.push('/login?aal2=true', { replace: true });
            return Promise.resolve();
          }

          if (responseData.error?.id === 'session_refresh_required' && responseData.redirect_browser_to) {
            console.warn('sdkError 403: Redirect browser to');
            window.location = responseData.redirect_browser_to;
            return Promise.resolve();
          }
          break;
        }
        case 404: {
          if (defaultNav !== undefined) {
            console.warn('sdkError 404: Navigate to Error');
            const errorMsg = {
              data: error.response?.data || error,
              status: error.response?.status,
              statusText: error.response?.statusText,
              url: window.location.href,
            };

            history.push(`/error?error=${encodeURIComponent(JSON.stringify(errorMsg))}`, {
              replace: true,
            });
            return Promise.resolve();
          }
          break;
        }
        case 410: {
          if (getFlow !== undefined && responseData.use_flow_id !== undefined) {
            console.warn('sdkError 410: Update flow');
            return getFlow(responseData.use_flow_id).catch((error) => {
              // Something went seriously wrong - log and redirect to defaultNav if possible
              console.error(error);

              if (defaultNav !== undefined) {
                history.push(defaultNav, { replace: true });
              } else {
                // Rethrow error when can't navigate and let caller handle
                throw error;
              }
            });
          } else if (defaultNav !== undefined) {
            console.warn('sdkError 410: Navigate to', defaultNav);
            history.push(defaultNav, { replace: true });
            return Promise.resolve();
          }
          break;
        }
        case 422: {
          if (responseData.redirect_browser_to !== undefined) {
            // GTL - removed handling for URLs I do not see in error messages.
            // just redirect. This fixes linking OIDC accounts. When it breaks something else
            // we can fix the new thing. Not shure which flows this will be though.

            console.warn('sdkError 422: Redirect browser to', responseData.redirect_browser_to);
            window.location = responseData.redirect_browser_to;
            return Promise.resolve();
          }
        }
      }

      console.error(error);

      if (fatalToDash) {
        console.warn('sdkError: fatal error redirect to dashboard');
        history.push('/', { replace: true });
        return Promise.resolve();
      }

      throw error;
    },
    [history, getFlow, defaultNav, setFlow, fatalToDash]
  );
};

export default new FrontendApi(config);
