import {
  ActionGroup,
  Button,
  Form,
  FormGroup,
  PageSection,
  Radio,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  TextInput,
  Alert,
  Bullseye,
  Spinner,
  FormAlert,
  Modal,
  ModalVariant,
  Page,
} from '@patternfly/react-core';
import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Class, UserType } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { GetClassResponse } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_pb';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

type AddMaterialProps = {
  classId: string;
};

type ValidatedProps = 'default' | 'success' | 'warning' | 'error';

const AddMaterials: React.FunctionComponent<AddMaterialProps> = () => {
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;
  const { classId } = useParams<AddMaterialProps>();
  const conf = React.useContext(GeneralSettingsContext);
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const [url, setUrl] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [response, setResponse] = React.useState<Response>();
  const [classroom, setClassroom] = React.useState<Class>();
  const [visibility, setVisibility] = React.useState<string>('');

  const [titleValidated, setTitleValidated] = React.useState<ValidatedProps>('default');
  const [urlValidated, setUrlValidated] = React.useState<ValidatedProps>('default');
  const [formValidated, setFormValidated] = React.useState<ValidatedProps>('default');
  const [urlHelperText, setUrlHelperText] = React.useState<string>('');
  const [visibilityValidated, setVisibilityValidated] = React.useState<ValidatedProps>('default');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  React.useMemo(async () => {
    const data = await fetch(`${conf.eduApi}/edu.v1.EduService/GetClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        classId: classId,
      }),
      credentials: 'include',
      cache: 'no-cache',
    }).then((response) => {
      setResponse(response);
      return response.json();
    });

    if (Object.hasOwnProperty.call(data, 'class')) {
      setClassroom(GetClassResponse.fromJson(data).class);
    }
  }, [classId]);

  if (classroom == undefined) {
    if (response && response.ok) {
      return (
        <React.Fragment>
          <PageSection>
            <Alert variant="danger" title="Not Found Error">
              <pre>This class does not exist</pre>
            </Alert>
          </PageSection>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        </React.Fragment>
      );
    }
  } else if (
    classroom.users[username] == undefined ||
    classroom.users[username] == UserType.STUDENT ||
    classroom.users[username] == UserType.UNSPECIFIED
  ) {
    return (
      <React.Fragment>
        <PageSection>
          <Alert variant="danger" title="Permission Error">
            <pre>You do not have permission to access this content</pre>
          </Alert>
        </PageSection>
      </React.Fragment>
    );
  }

  const handleTitle = (value: string) => {
    setTitle(value);
    value = value.trim();
    if (value == '') {
      setTitleValidated('error');
    } else {
      setTitleValidated('success');
    }
  };

  const handleUrl = (value: string) => {
    /* Regex to check if it is a legal url
     * (mostly checking for http/https because otherwise it tries
     * to redirect to a page in launch that most likely doesn't exist)
     */
    const urlRegex = new RegExp('\\b((?:(https?|ftp)://)[\\w-]+(.[\\w-]+)+[\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-]?)');

    setUrl(value);
    value = value.trim();
    if (value == '') {
      setUrlValidated('error');
    } else if (!urlRegex.test(value)) {
      setUrlValidated('warning');
      setUrlHelperText('May not be a valid URL (you should include "http://" or "https://" at the beginning)');
    } else {
      setUrlValidated('success');
      setUrlHelperText('');
    }
  };

  const handleVisibility = (checked: boolean, event: React.FormEvent<HTMLInputElement> | undefined) => {
    if (!event) {
      if (visibility == '') {
        setVisibilityValidated('error');
      }
    } else {
      switch (event.currentTarget.id) {
        case 'visibility-radio-1':
          setVisibility('all');
          setVisibilityValidated('success');
          break;
        case 'visibility-radio-2':
          setVisibility('prof');
          setVisibilityValidated('success');
          break;
        default:
          setVisibility('');
          setVisibilityValidated('error');
          break;
      }
    }
  };

  const handleSubmit = async () => {
    // Check for validity
    if (titleValidated != 'success' || urlValidated != 'success' || visibility == '') {
      setFormValidated('error');
      handleTitle(title);
      handleUrl(url);
      handleVisibility(true, undefined);
      return;
    } else {
      setFormValidated('success');
    }

    // Replace every nonalphanumeric character with -, then lowercase it
    const formattedTitle = title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();

    const materialId = `${classId}-material-${formattedTitle}`;

    // Create Material
    const resp = await client
      .createMaterial({
        materialId: materialId,
        displayName: title,
        classId: classId,
        url: url,
        isVisibleToAll: visibility === 'all',
      })
      .then(() => {
        setUrl('');
        setTitle('');
        setVisibility('');
      })
      .catch(() => {
        // Using warning to indicate 409 Conflict
        setFormValidated('warning');
      });

    // Go back to class page
    history.back();
  };

  return (
    <React.Fragment>
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}`}>Class {classId}</BreadcrumbItem>
          <BreadcrumbItem isActive>Add Materials to {classId}</BreadcrumbItem>
        </Breadcrumb>
        <Title headingLevel="h1">Add materials to {classId}</Title>
        <Modal
          variant={ModalVariant.small}
          title="Confirm Cancel?"
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          actions={[
            <Button
              key="confirm"
              variant="primary"
              onClick={() => {
                history.back();
                setIsModalOpen(false);
              }}
            >
              Yes
            </Button>,
            <Button
              key="cancel"
              variant="link"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              No
            </Button>,
          ]}
        >
          {`Are you sure you want to cancel? Unsaved changes will be lost.`}
        </Modal>
        <Form>
          {formValidated === 'error' && (
            <FormAlert>
              <Alert
                variant="danger"
                title="Fill out all required fields before continuing."
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          {formValidated === 'warning' && (
            <FormAlert>
              <Alert variant="danger" title="Material already exists." aria-live="polite" isInline />
            </FormAlert>
          )}
          {formValidated === 'success' && (
            <FormAlert>
              <Alert variant="success" title="Successfully added material." aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup
            label="Title"
            isRequired
            fieldId="add-material-title"
            helperTextInvalid={'Title cannot be empty'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={titleValidated}
          >
            <TextInput
              validated={titleValidated}
              isRequired
              type="text"
              id="add-material-title"
              name="add-material-title"
              value={title}
              onChange={handleTitle}
            />
          </FormGroup>
          <FormGroup
            label="Material URL"
            isRequired
            fieldId="add-material-url"
            helperText={urlHelperText}
            helperTextInvalid={'URL cannot be empty'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={urlValidated}
          >
            <TextInput
              validated={urlValidated}
              isRequired
              type="url"
              id="add-material-url"
              name="add-material-url"
              value={url}
              onChange={handleUrl}
            />
          </FormGroup>
          <FormGroup
            isRequired
            role="radiogroup"
            isInline
            fieldId="visibility-radio-group"
            label="Visibility"
            helperTextInvalid={'Select visibility'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={visibilityValidated}
          >
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'all'}
              label="All"
              id="visibility-radio-1"
            />
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'prof'}
              label="Professors/TAs Only"
              id="visibility-radio-2"
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={handleSubmit}>
              Add
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Cancel
            </Button>
          </ActionGroup>
        </Form>
      </PageSection>
      <PageSection>
        You may want to select a Shared Material. Please browse the list
        <a href={'https://jelenamirkovic.github.io/sphere-education.github.io/'} target="_blank" rel="noreferrer">
          {' '}
          here
        </a>
        .
      </PageSection>
    </React.Fragment>
  );
};

export { AddMaterials };
