import * as React from 'react';
import ory, { sdkError } from '@app/lib/OrySdk';
import { FlowError } from '@ory/client';
import { UserErrorCard } from '@ory/elements';
import { Card, CardBody, CardTitle, PageSection, Spinner } from '@patternfly/react-core';

interface ErrorProps {
  code: number;
  message: string;
  details: {};
}

const ErrorPage: React.FC<ErrorProps> = ({ code, message, details }) => {
  // Check for id in params put there by Ory
  const [flowError, setFlowError] = React.useState<FlowError>();
  const params = new URLSearchParams(document.location.search);
  const id = params.get('id');

  React.useEffect(() => {
    if (id) {
      ory.getFlowError({ id: id }).then(({ data: fe }) => setFlowError(fe));
    }
  }, [id]);

  console.log('flow error', flowError);

  return (
    <React.Fragment>
      <PageSection>
        {flowError ? <UserErrorCard title={'Identity Error'} error={flowError} /> : <Spinner />}
      </PageSection>
    </React.Fragment>
  );
};

export { ErrorPage };
