class Mutex {
  private queue: (() => void)[] = [];
  private locked = false;

  async acquire(): Promise<() => void> {
    // Create a new promise and store its resolver
    let release: () => void;

    const promise = new Promise<void>(resolve => {
      release = resolve;
    });

    // If the mutex is locked, queue the release function
    if (this.locked) {
      this.queue.push(release!);
    } else {
      // Otherwise, lock the mutex and return immediately
      this.locked = true;
      release!();
    }

    // Return a function to release the lock
    return async () => {
      if (this.queue.length > 0) {
        // Take the next release function from the queue
        const next = this.queue.shift();
        next!(); // Call the release function to unlock the next waiting promise
      } else {
        // No more tasks waiting, so unlock the mutex
        this.locked = false;
      }
    };
  }
}

export default Mutex;