import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { Alert, Breadcrumb, BreadcrumbItem, Card, PageSection, Spinner, Title } from '@patternfly/react-core';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Class, UserType } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { AuthContext } from '@app/lib/AuthProvider';
import {
  GetOrganizationResponse,
  Member_Role,
  UserState,
  Organization as PortalOrg,
} from '@mergetb/api/portal/v1/workspace_types';

import { useFetch } from 'use-http';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient, ConnectError, Code } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

type ClassroomProp = {
  oid: string;
};

const Classroom: React.FunctionComponent<ClassroomProp> = () => {
  const [org, setOrg] = React.useState<PortalOrg>();
  const [reload, setReload] = React.useState<boolean>(false);
  const { oid } = useParams<ClassroomProp>();
  const conf = React.useContext(GeneralSettingsContext);

  const { data: orgData, loading: orgLoading } = useFetch(
    `${conf.api}/organization/${oid}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
    },
    []
  );

  const edu = React.useMemo(() => {
    const transport = createConnectTransport({
      baseUrl: `${conf.eduApi}`,
      credentials: 'include',
    });
    return createPromiseClient(EduService, transport);
  }, [conf.eduApi]);

  // load the org from the portal
  React.useEffect(() => {
    if (orgData && Object.hasOwnProperty.call(orgData, 'organization')) {
      setOrg(GetOrganizationResponse.fromJSON(orgData).organization);
    }
  }, [orgData]);

  // load the class from the org
  React.useEffect(() => {
    if (org?.state === UserState.Active && org.category === 'Class') {
      edu
        .getClass({ classId: org?.name })
        .then((resp) => {
          console.log('found class', resp.class);
        })
        .catch((err) => {
          if (err instanceof ConnectError && err.code == Code.NotFound) {
            // create the class. The org is active and class type, so init edu with the org.
            const users: { [key: string]: UserType } = {};
            const keys = Object.keys(org.members); // array of usernames

            console.log('keys', keys);
            keys.forEach((m) => {
              users[m] =
                org.members[m].role === Member_Role.Creator
                  ? UserType.PROFESSOR
                  : org.members[m].role == Member_Role.Maintainer
                  ? UserType.TA
                  : UserType.STUDENT;
            });

            console.log('users', users);

            // create the users. We do not use password or email for existing users.
            Object.keys(users).forEach((u) => {
              const classes: { [key: string]: UserType } = {};
              classes[u] = users[u];
              edu
                .createUser({
                  userId: u,
                  fullName: u,
                  classes: classes,
                })
                .catch((e) => console.log('create user error'));
            });

            // and create teh class
            edu
              .createClass({ classId: org?.name, users: users })
              .then((resp) => {
                if (resp) {
                  // reload somehow
                  setReload(true);
                }
              })
              .catch((e) => {
                console.log('createclass error');
              });
          }
          console.log('unknown error', err);
        });
    }
  }, [org, reload, edu]);

  // console.log([org, edu, orgData]);
  console.log('orgLoading', orgLoading);

  if (orgLoading) {
    return (
      <>
        <PageSection>
          <Card>
            Loading Portal Organization
            <Spinner />
          </Card>
        </PageSection>
      </>
    );
  }

  if (org === undefined) {
    return (
      <>
        <PageSection>
          <Alert variant="danger" title="SPHERE Organization Error">
            {`There is no SPHERE organization ${oid}`}
          </Alert>
        </PageSection>
      </>
    );
  }

  // check for active org.
  if (org) {
    console.log('org', org);
    if (org.category !== 'Class') {
      return (
        <>
          <PageSection>
            <Alert variant="danger" title="SPHERE Organization Error">
              {`The SPHERE organization for ${oid} is not a class. Contact a SPHERE admin.`}
            </Alert>
          </PageSection>
        </>
      );
    }
    if (org.state !== UserState.Active) {
      return (
        <>
          <PageSection>
            <Alert variant="danger" title="SPHERE Organization Error">
              {`The SPHERE organization for class ${oid} is not active. Contact a SPHERE admin.`}
            </Alert>
          </PageSection>
        </>
      );
    }
  }

  const cols = ['Users', 'Materials', 'Assignments'];

  return (
    <React.Fragment>
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem isActive>Class {org.name}</BreadcrumbItem>
        </Breadcrumb>
        <Title headingLevel="h1">
          Manage class <Link to={`${conf.launch}/organization/${org.name}}`}>{oid}</Link>
        </Title>
        <TableComposable variant={'compact'} borders={false}>
          <Thead>
            <Tr>
              {cols.map((c) => {
                return (
                  <Th key={`class-table-head-${c.toLowerCase()}`} hasRightBorder>
                    {c}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {cols.map((c) => {
                return (
                  <Td key={`class-table-body-add-${c.toLowerCase()}`} dataLabel={c}>
                    <Link to={`/edu/class/${org.name}/add${c.toLowerCase()}`}>{`Add ${c}`}</Link>
                  </Td>
                );
              })}
            </Tr>
            <Tr>
              {cols.map((c, i) => {
                return (
                  <Td key={`class-table-body-manage-${c.toLowerCase()}`} dataLabel={c}>
                    <Link to={`/edu/class/${org.name}/${c.toLowerCase()}`}>{`Manage ${c}`}</Link>
                  </Td>
                );
              })}
            </Tr>
          </Tbody>
        </TableComposable>
      </PageSection>
    </React.Fragment>
  );
};

export { Classroom };
