import * as React from 'react';
import { FileUpload } from '@patternfly/react-core';
import { UploadFileRequest, UploadFileResponse } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_pb';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import Mutex from './FileUploadMutex';

type SubmissionFileUploadProps = {
  classId: string;
  userId: string;
  assignmentId: string;
  onClick: (filename: string) => void;
};

const CHUNK_SIZE = 512 * 1024; // 512KB (adjust based on your requirements)
let mutex = new Mutex();

const SubmissionFileUpload: React.FunctionComponent<SubmissionFileUploadProps> = ({
  classId,
  userId,
  assignmentId,
  onClick,
}) => {
  const conf = React.useContext(GeneralSettingsContext);
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);
  const [value, setValue] = React.useState<File>();
  const [filename, setFilename] = React.useState('');

  // Generator function that gives the next chunk of a file as an AsyncIterator
  async function* generateChunk(file: File, chunkNumber: number, totalChunks: number) {
    const reader = file.stream().getReader();
    let buffer = new Uint8Array(); // To accumulate chunks
    let result = await reader.read();

    while (!result.done) {
      // Concatenate new data to buffer
      const newChunk = result.value ?? new Uint8Array();
      const newBuffer = new Uint8Array(buffer.length + newChunk.length);
      newBuffer.set(buffer);
      newBuffer.set(newChunk, buffer.length);
      buffer = newBuffer;

      // Process full chunks
      while (buffer.length >= CHUNK_SIZE) {
        // Mutex to prevent weird shenanigans like sending the same chunk number multiple times
        const release = await mutex.acquire();
        // console.log(chunkNumber, "aquired the mutex");

        // Extract a chunk of the desired size
        const chunk = buffer.slice(0, CHUNK_SIZE);
        buffer = buffer.slice(CHUNK_SIZE);

        // Yield the chunk
        yield new UploadFileRequest({
          fileId: userId,
          bucketId: `${assignmentId}`,
          totalChunks: totalChunks,
          chunkNumber: chunkNumber,
          data: chunk,
          fileType: file.type,
        });

        chunkNumber++;

        // Release the mutex for the next chunk
        release();
        // console.log(chunkNumber, "released the mutex");
      }

      // Read the next chunk
      result = await reader.read();
    }

    // Process any remaining data in the buffer
    if (buffer.length > 0) {
      const chunk = buffer;

      yield new UploadFileRequest({
        fileId: userId,
        bucketId: `${assignmentId}`,
        totalChunks: totalChunks,
        chunkNumber: chunkNumber,
        data: chunk,
        fileType: file.type,
      });
    }

    console.log('File reading complete');
    console.log('file size:', file.size, 'MIME type', file.type);
  }

  const handleUploadFile = async (_event: any, file: File) => {
    setValue(file);
    setFilename(file.name);
    onClick(file.name);

    // Create Submission
    client
      .uploadSubmission({
        userId: userId,
        classId: classId,
        assignmentId: assignmentId,
        filename: file.name,
        fileType: file.type,
      })
      .catch((error) => error);

    // Create FileData

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    const chunkProgress = 100 / totalChunks;

    for await (const chunk of generateChunk(file, 0, totalChunks)) {
      await client.uploadFile(chunk);
      console.log('Uploaded chunk', chunk.chunkNumber + 1, 'of', totalChunks, 'with size', chunk.data.byteLength);
    }
  };

  const handleClear = async () => {
    setValue(undefined);
    setFilename('');
  };

  return (
    <FileUpload
      id={`${assignmentId}-file-upload`}
      value={value}
      filename={filename}
      filenamePlaceholder="Drag and drop a file or upload one"
      onFileInputChange={handleUploadFile}
      onClearClick={handleClear}
      browseButtonText="Upload"
    />
  );
};

export { SubmissionFileUpload };

