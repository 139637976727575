import * as React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Card,
  CardBody,
  EmptyState,
  EmptyStateBody,
  EmptyStateIcon,
  EmptyStateVariant,
  PageSection,
  Spinner,
  Stack,
  StackItem,
  Tabs,
  Tab,
  TabTitleText,
  Title,
  Text,
  Modal,
  ModalVariant,
  Progress,
  ProgressMeasureLocation,
} from '@patternfly/react-core';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  GetOrganizationsResponse,
  Member_Role,
  Organization,
} from '@mergetb/api/portal/v1/workspace_types';
import { Link } from 'react-router-dom';
import { Thead, Tr, Th, Tbody, Td, Table } from '@patternfly/react-table';
import { Material, Assignment } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { SubmissionFileUpload } from '@app/lib/SubmissionFileUpload';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { SearchIcon } from '@patternfly/react-icons';
import { Timestamp } from '@bufbuild/protobuf';
import { SubmissionFilesDownload } from '@app/lib/SubmissionFilesDownload';

interface ClassOrgProps {
  client: PromiseClient<typeof EduService>;
  org: Organization;
  uid: string;
  isProfOrTA?: boolean;
  isAdmin?: boolean;
}

const renderEmptyState = (dataType: string) => (
  <EmptyState variant={EmptyStateVariant.xs}>
    <EmptyStateIcon icon={SearchIcon} />
    <Title headingLevel="h5" size="lg">
      {`No ${dataType}s available`}
    </Title>
    <EmptyStateBody>{`There are currently no ${dataType}s to display.`}</EmptyStateBody>
  </EmptyState>
);

const GetUserAssignments: React.FunctionComponent<ClassOrgProps> = ({ client, uid, org, isProfOrTA = false }) => {
  const conf = React.useContext(GeneralSettingsContext);
  const [assignments, setAssignments] = React.useState<Assignment[]>([]);
  const [visibleAssignments, setVisibleAssignments] = React.useState<Assignment[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [downloadProgress, setDownloadProgress] = React.useState<number>(0);

  // Fetch all assignments
  React.useEffect(() => {
    if (org == undefined) return;

    client
      .getAssignments({
        classId: org.name,
      })
      .then((response) => {
        setAssignments(response.assignments);
      })
      .catch((error) => error);
  }, [org]); // Update every time org changes . GTL: does it change?

  // Calculate visible assignments
  React.useEffect(() => {
    const visAssigns = assignments.filter((a) => {
      // Check if release date is after current date
      if (a.releaseDate!.seconds > Date.now() / 1000) {
        // Return true if the user if a professor or TA
        return isProfOrTA;
      }
      // Check if its visible to only profs/TAs
      else if (!a.isVisibleToAll) {
        // Return true if the user if a professor or TA
        return isProfOrTA;
      } else {
        // Return true otherwise
        return true;
      }
    });

    setVisibleAssignments(visAssigns);
  }, [assignments, isProfOrTA]); // Update every time the assignments array changes

  const cols = {
    title: 'Title',
    dueDate: 'Due',
    releaseDate: 'Released',
    submission: 'Submitted',
    submit: 'Submit',
  };

  const renderReleaseDate = (releaseDate: Timestamp | undefined) => {
    if (releaseDate) {
      if (releaseDate?.seconds > Date.now() / 1000) {
        return <React.Fragment>
          {releaseDate.toDate().toLocaleString()}
          <Text style={{ color: 'red' }}>
            {' (unreleased)'}
          </Text>
        </React.Fragment>
      } else {
        return <React.Fragment>
          {releaseDate.toDate().toLocaleString()}
        </React.Fragment>
      }

    } else {
      return <Text style={{ color: 'red' }}>
        {'(empty)'}
      </Text>
    }
  }

  const renderSubmissionFileDownloadButton = (isProfOrTA: boolean, assignmentId: string) => {
    if (isProfOrTA) {
      return <SubmissionFilesDownload
        classId={org.name}
        assignmentId={assignmentId}
        downloadProgress={(progress) => {
          // Rounds to 2 decimal places
          setDownloadProgress(Math.round(progress * 100) / 100)

          // Just in case its not exactly 100 due to rounding error
          // Would take a single file with 10,000 chunks or 100 files with 100 chunks each to trigger this prematurely
          if (progress >= 99.99) {
            setIsModalOpen(false);
            setDownloadProgress(0);
          }
        }}
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
    } else {
      return <React.Fragment>
        Submission closed
      </React.Fragment>
    }
  }

  return (
    <React.Fragment>
      <Modal
        title="Download Progress"
        variant={ModalVariant.small}
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
      >
        You can safely close this modal. The download will continue in the background.
        <Progress value={downloadProgress} measureLocation={ProgressMeasureLocation.inside} />
      </Modal>
      <Table aria-label="Class Assignments" variant={'compact'} borders={false} key={`assignments-table-${org.name}`}>
        <Thead key={`assignments-table-head-${org.name}`}>
          <Tr>
            <Th key={1}>{cols.title}</Th>
            <Th key={2}>{cols.dueDate}</Th>
            <Th key={3}>{cols.releaseDate}</Th>
            <Th key={4}>{cols.submission}</Th>
            <Th key={5}>{cols.submit}</Th>
          </Tr>
        </Thead>
        {/* If there are no visible assignments, then render empty state */}
        {visibleAssignments.length == 0 && (
          <Tbody>
            <Tr>
              <Td colSpan={Object.keys(cols).length}>{renderEmptyState('assignment')}</Td>
            </Tr>
          </Tbody>
        )}
        {/* Otherwise, render all visible assignments */}
        {visibleAssignments.map((a, i) => {
          return (
            <Tbody key={`assignments-table-body-${org.name}-${i}`}>
              <Tr>
                <Td key={`assignments-table-body-${org.name}-${i}-${a.assignmentId}-1`} dataLabel={cols.title}>
                  <Link to={{ pathname: `${a.material?.url}` }} target="_blank">
                    {a.displayName}
                  </Link>
                  {!a.isVisibleToAll && (<Text style={{ color: 'red' }}>
                    {'(Only visible to prof/TAs)'}
                  </Text>)}
                </Td>
                <Td key={`assignments-table-body-${org.name}-${i}-${a.assignmentId}-2`} dataLabel={cols.dueDate}>
                  {a.dueDate?.toDate().toLocaleString()}
                </Td>
                <Td key={`assignments-table-body-${org.name}-${i}-${a.assignmentId}-3`} dataLabel={cols.releaseDate}>
                  {renderReleaseDate(a.releaseDate)}
                </Td>
                <Td key={`assignments-table-body-${org.name}-${i}-${a.assignmentId}-4`} dataLabel={cols.submission}>
                  {a.submissions[uid] ?? 'No submission'}
                </Td>
                <Td key={`assignments-table-body-${org.name}-${i}-${a.assignmentId}-5`} dataLabel={cols.submit}>
                  {a.dueDate!.seconds < Date.now() / 1000 ? (
                    renderSubmissionFileDownloadButton(isProfOrTA, a.assignmentId)
                  ) : (
                    <SubmissionFileUpload
                      classId={org.name}
                      userId={uid}
                      assignmentId={a.assignmentId}
                      onClick={(filename) => {
                        const newAssigns = assignments.map((as) => {
                          if (as.assignmentId !== a.assignmentId) return as;
                          const newSubs = as.submissions;
                          newSubs[uid] = filename;
                          return new Assignment({
                            ...as,
                            submissions: newSubs,
                          });
                        });
                        setAssignments(newAssigns);
                      }}
                    />
                  )}
                </Td>
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </React.Fragment>
  );
};

const GetClassMaterials: React.FunctionComponent<ClassOrgProps> = ({ client, org, isProfOrTA = false }) => {
  const conf = React.useContext(GeneralSettingsContext);
  const [materials, setMaterials] = React.useState<Material[]>([]);
  const [visibleMaterials, setVisibleMaterials] = React.useState<Material[]>([]);

  // Get all materials
  React.useEffect(() => {
    if (org == undefined) return;

    client
      .getMaterials({
        classId: org.name,
      })
      .then((response) => {
        setMaterials(response.materials);
      })
      .catch((error) => error);
  }, [org, client]);

  // Calculate visible materials
  React.useEffect(() => {
    const visMats = materials.filter((a) => {
      // Check if its visible to only profs/TAs
      if (!a.isVisibleToAll) {
        // Return true if the user if a professor or TA
        return isProfOrTA;
      } else {
        // Return true otherwise
        return true;
      }
    });

    setVisibleMaterials(visMats);
  }, [materials, isProfOrTA]); // Update every time the assignments array changes

  const cols = {
    title: 'Title',
  };

  return (
    <React.Fragment>
      <Table
        aria-label={`Class Materials ${org.name}`}
        variant={'compact'}
        borders={false}
        key={`materials-table-${org.name}`}
      >
        <Thead key={`materials-table-head-${org.name}`}>
          <Tr>
            <Th>{cols.title}</Th>
          </Tr>
        </Thead>
        {/* If there are no visible assignments, then render empty state */}
        {visibleMaterials.length == 0 && (
          <Tbody>
            <Tr>
              <Td colSpan={Object.keys(cols).length}>{renderEmptyState('material')}</Td>
            </Tr>
          </Tbody>
        )}
        {/* Otherwise, render all visible assignments */}
        {visibleMaterials.map((mat, i) => {
          return (
            <Tbody key={`materials-table-body-${org.name}-${i}`}>
              <Tr>
                <Td dataLabel={cols.title}>
                  <Link to={{ pathname: `${mat.url}` }} target="_blank">
                    {mat.displayName}
                  </Link>
                  {!mat.isVisibleToAll && (<Text style={{ color: 'red' }}>
                    {'(Only visible to prof/TAs)'}
                  </Text>)}
                </Td>
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </React.Fragment>
  );
};

const ClassOrg: React.FunctionComponent<ClassOrgProps> = ({ client, org, uid, isAdmin = false }) => {
  const conf = React.useContext(GeneralSettingsContext);

  const isProfOrTA = (uid: string, org: Organization | undefined) => {
    if (!org) {
      return false;
    }
    if (isAdmin) {
      return true;
    } else if (org.members[uid].role == Member_Role.Creator || org.members[uid].role == Member_Role.Maintainer) {
      return true;
    }
    return false;
  };

  return (
    <Card>
      <CardBody>
        <Stack hasGutter>
          <StackItem>
            <Title headingLevel="h3">Materials</Title>
            {GetClassMaterials({ client: client, org: org, uid: uid, isProfOrTA: isProfOrTA(uid, org) })}
          </StackItem>
          <StackItem>
            <Title headingLevel="h3">Assignments</Title>
            {GetUserAssignments({ client: client, org: org, uid: uid, isProfOrTA: isProfOrTA(uid, org) })}
          </StackItem>
        </Stack>
      </CardBody>
    </Card>
  );
};

const MyClasses: React.FunctionComponent = () => {
  const conf = React.useContext(GeneralSettingsContext);
  const { isAuthenticated, session, identity } = React.useContext(AuthContext);
  const [orgs, setOrgs] = React.useState<Organization[]>();
  const username = identity?.traits.username;
  const [tabKey, setTabKey] = React.useState<number>(0);

  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  React.useEffect(() => {
    // const req: GetOrganizationsRequest = { filter: FilterMode.ByUser };
    // default filter is byUser
    fetch(`${conf.api}/organization`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      cache: 'no-cache',
      // body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw new Error('error fetching orgs');
      })
      .then((json) => {
        // filter out non-class orgs.
        setOrgs(GetOrganizationsResponse.fromJSON(json).organizations.filter((o) => o.category === 'Class'));
      })
      .catch((e) => console.log('get orgs error', e));
  }, [conf]);

  // THis is the landing page, so redirect if the user is not logged in.
  if (!isAuthenticated || !session) {
    return <Redirect to="/login" />;
  }

  const handleTabClick = (event, i) => {
    setTabKey(i);
  };

  return (
    <PageSection>
      <Title headingLevel="h1">My Classes</Title>
      {orgs ? (
        <Tabs isBox activeKey={tabKey} onSelect={handleTabClick} aria-label="My Classes">
          {orgs.map((o, i) => (
            <Tab key={i} eventKey={i} title={<TabTitleText>{o.name}</TabTitleText>}>
              <ClassOrg client={client} org={o} uid={username} key={`class-${o}`} />
            </Tab>
          ))}
        </Tabs>
      ) : (
        <Spinner />
      )}
    </PageSection>
  );
};

export { MyClasses };
