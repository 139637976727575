import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { Title } from '@patternfly/react-core';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
// import { User } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb'
import { GetClassResponse } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_pb';
import { UserType } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb'
import { GetOrganizationsResponse, Member, Organization, User } from '@mergetb/api/portal/v1/workspace_types';
import { GetUserResponse } from '@mergetb/api/portal/v1/workspace_types';
import { AccessMode } from '@mergetb/api/portal/v1/workspace_types';
import { Member_Role } from '@mergetb/api/portal/v1/workspace_types';


const PopulateDatabase: React.FunctionComponent = () => {
  const conf = React.useContext(GeneralSettingsContext);
  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const { get, response } = useFetch(`${conf.api}/organization?filter=ByAll`, options);
  const [orgs, setOrgs] = React.useState<Organization[]>([]);
  const [users, setUsers] = React.useState<Member[]>([]);

  const getOrgs = React.useMemo(async () => {
    const data = await get();
    console.log(data);
    if (response.ok) {
      setOrgs(GetOrganizationsResponse.fromJSON(data).organizations);
    }
  }, [response, get, setOrgs]);

  orgs.map(async o => {
    // Only run if crg.category = class
    if (o.category.toLowerCase() == 'class') {
      console.log(`Add class ${o.name}`)

      // Create SPHERE Class using Merge Org
      fetch(conf.eduApi + '/edu.v1.EduService/CreateClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          class_id: o.name,
        }),
        credentials: 'include',
        cache: 'no-cache',
      }).then(response => response.json())
        .catch(error => console.error('Error:', error));

      // Create users in org and add to class
      await Promise.all(Object.keys(o.members).map(async m => {

        // Get Merge User
        const userRes = await fetch(conf.api + '/user/' + m, options)
          .then(response => response.json())
          .catch(error => console.error('Error:', error));

        const user = GetUserResponse.fromJSON(userRes).user;

        if (!user) {
          console.error(`Failed to get user ${m}`)
          return;
        }

        // Create SPHERE User
        fetch(conf.eduApi + '/edu.v1.EduService/CreateUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_id: user.username,
            full_name: user.name,
          }),
          credentials: 'include',
          cache: 'no-cache',
        }).then(response => response.ok ? console.log(`Added user ${m}`) : console.error(`Failed to create user ${m}`))
          .catch(error => console.error('Error:', error));

        const username = user.username;
        const userType = user.admin ? UserType.ADMIN :
          o.members[m].role == Member_Role.Creator ? UserType.PROFESSOR :
            o.members[m].role == Member_Role.Maintainer ? UserType.TA :
              o.members[m].role == Member_Role.Member ? UserType.STUDENT :
                UserType.UNSPECIFIED;

        // Add SPHERE User to Class
        fetch(conf.eduApi + '/edu.v1.EduService/AddUsersToClass', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            users: {
              [username]: userType,
            },
            class_id: o.name,
          }),
          credentials: 'include',
          cache: 'no-cache',
        }).then(response => response.ok ? console.log(`Added user ${m} to ${o.name}`) : console.error(`Failed to add user ${m} to ${o.name}`))
          .catch(error => console.error('Error:', error));


      }));
    }



  })

  return (<React.Fragment>
    <Title headingLevel='h1'>
      {"populating"}
    </Title>
  </React.Fragment>);
};

export { PopulateDatabase };