import * as React from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  Nav,
  NavList,
  NavItem,
  NavExpandable,
  Page,
  PageSidebar,
  SkipToContent,
  Masthead,
  MastheadMain,
  MastheadToggle,
  PageToggleButton,
  MastheadContent,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  ToolbarGroupVariant,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  ButtonVariant,
  Title,
  TitleSizes,
} from '@patternfly/react-core';
import { routes, IAppRoute, IAppRouteGroup } from '@app/routes';
import logo from '@app/bgimages/launch-logo.svg';
import { AccountMenu } from '@app/AccountMenu/AccountMenu';
import { BarsIcon } from '@patternfly/react-icons';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import BugIcon from '@patternfly/react-icons/dist/esm/icons/bug-icon';
import EnvelopeIcon from '@patternfly/react-icons/dist/esm/icons/envelope-icon';
import GitlabIcon from '@patternfly/react-icons/dist/esm/icons/gitlab-icon';
import ChatIcon from '@patternfly/react-icons/dist/esm/icons/chat-icon';
import CatalogIcon from '@patternfly/react-icons/dist/esm/icons/catalog-icon';
import { About } from '@app/About/About';
import { useTranslation } from 'react-i18next';

interface IAppLayout {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [isMobileView, setIsMobileView] = React.useState(true);
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false);
  const [helpDropdownOpen, setHelpDropdownOpen] = React.useState(false);
  const conf = React.useContext(GeneralSettingsContext);

  const { t } = useTranslation();

  const [isAboutOpen, setIsAboutOpen] = React.useState(false);
  const toggleAbout = () => {
    setIsAboutOpen(!isAboutOpen);
  };

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile);
  };
  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  function LogoImg() {
    const history = useHistory();
    function handleClick() {
      history.push('/');
    }
    return <img src={logo} onClick={handleClick} alt="Merge Logo" />;
  }

  const helpDropdownToggle = (isOpen: boolean) => {
    setHelpDropdownOpen(isOpen);
  };

  const helpDropdownSelect = () => {
    setHelpDropdownOpen(!helpDropdownOpen);
  };

  const helpDropdownItems = [
    <DropdownItem
      component="a"
      key="documentation"
      href="https://mergetb.gitlab.io/testbeds/sphere/sphere-docs/docs/experimentation/"
      icon={<CatalogIcon />}
      target="blank"
    >
      Experiment Documentation
    </DropdownItem>,
    <DropdownItem key="chat" icon={<ChatIcon />} component="a" href="https://chat.mergetb.net/mergetb" target="blank">
      Support Chat
    </DropdownItem>,
    <DropdownItem
      key="source-code"
      icon={<GitlabIcon />}
      component="a"
      href="https://gitlab.com/mergetb"
      target="blank"
    >
      Source Code
    </DropdownItem>,
    <DropdownItem
      key="email-support"
      icon={<EnvelopeIcon />}
      component="a"
      href="mailto:contact-project+mergetb-support-email@incoming.gitlab.com"
      target="blank"
    >
      Email Support
    </DropdownItem>,
    <DropdownItem
      key="mergetb-issues"
      icon={<BugIcon />}
      component="a"
      description="Requires Gitlab account"
      href="https://gitlab.com/mergetb/support/-/issues"
      target="blank"
    >
      MergeTB Issues
    </DropdownItem>,
  ];

  const headerToolbar = (
    <Toolbar id="toolbar" isFullHeight isStatic>
      <ToolbarContent>
        <ToolbarGroup
          variant={ToolbarGroupVariant['icon-button-group']}
          alignment={{ default: 'alignRight' }}
          spacer={{ default: 'spacerNone', md: 'spacerMd' }}
        >
          <ToolbarItem>
            <Button component="a" href="https://jelenamirkovic.github.io/sphere-education.github.io/" target="_blank" variant={ButtonVariant.plain}>
              Shared Materials
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button component="a" href="https://launch.sphere-testbed.net" target="blank" variant={ButtonVariant.plain}>
              SPHERE UI
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Dropdown
              isPlain
              isFullHeight
              onSelect={helpDropdownSelect}
              toggle={
                <DropdownToggle onToggle={helpDropdownToggle} id="toggle-help">
                  Support
                </DropdownToggle>
              }
              isOpen={helpDropdownOpen}
              dropdownItems={helpDropdownItems}
            />
          </ToolbarItem>
          <ToolbarItem>
            <AccountMenu />
          </ToolbarItem>
          <ToolbarItem>
            <Button variant="plain" onClick={toggleAbout}>
              About
            </Button>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const Header = (
    <Masthead>
      <MastheadToggle>
        <PageToggleButton variant="plain" aria-label="Global navigation" onClick={onNavToggle}>
          <BarsIcon />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <Title headingLevel="h3" size={TitleSizes['3xl']}>
          SPHERE Education
        </Title>
      </MastheadMain>
      <MastheadContent>{headerToolbar}</MastheadContent>
    </Masthead>
  );

  const location = useLocation();

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`}>
      <NavLink exact to={route.path} activeClassName="pf-m-current">
        {route.label}
      </NavLink>
    </NavItem>
  );

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <NavExpandable
      isExpanded={group?.isExpanded === true ? true : false}
      key={`${group.label}-${groupIndex}`}
      id={`${group.label}-${groupIndex}`}
      title={group.label}
      isActive={group.routes.some((route) => route.path === location.pathname)}
    >
      {group.routes.map((route, idx) => route.label && renderNavItem(route, idx))}
    </NavExpandable>
  );

  const Navigation = (
    <Nav id="nav-primary-simple" theme="dark">
      <NavList id="nav-list-simple">
        {routes(t).map(
          (route, idx) => route.label && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx))
        )}
      </NavList>
    </Nav>
  );
  const Sidebar = <PageSidebar theme="dark" nav={Navigation} isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen} />;
  const PageSkipToContent = <SkipToContent href="#primary-app-container">Skip to Content</SkipToContent>;

  return (
    <Page
      className="merge-styles"
      mainContainerId="primary-app-container"
      header={Header}
      sidebar={Sidebar}
      onPageResize={onPageResize}
      skipToContent={PageSkipToContent}
    >
      {isAboutOpen && <About toggle={toggleAbout} isOpen={isAboutOpen} />}
      {children}
    </Page>
  );
};

export { AppLayout };
